import {
  ADMIN_FETCH_SH_INIT,
  ADMIN_FETCH_SH_CLIENT_REQUEST,
  ADMIN_FETCH_SH_CLIENT_SUCCESS,
  ADMIN_FETCH_SH_CLIENT_FAILURE,
  ADMIN_FETCH_SH_STRIPE_CUSTOMERS_REQUEST,
  ADMIN_FETCH_SH_STRIPE_CUSTOMERS_SUCCESS,
  ADMIN_FETCH_SH_STRIPE_CUSTOMERS_FAILURE,
  ADMIN_CREATE_STRIPE_CUSTOMERS_REQUEST,
  ADMIN_CREATE_STRIPE_CUSTOMERS_SUCCESS,
  ADMIN_CREATE_STRIPE_CUSTOMERS_FAILURE,
} from '../../actions/types';

const initialState = {
  client: null,
  client_loading: false,
  stripe_customers: [],
  stripe_customers_loading: false,
};

// for one client
const ScheduleHelperReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_FETCH_SH_INIT:
      return initialState;
    case ADMIN_FETCH_SH_CLIENT_REQUEST:
      return { ...state, client: initialState.client, client_loading: true };
    case ADMIN_FETCH_SH_CLIENT_SUCCESS:
      return { ...state, client: action.payload, client_loading: false };
    case ADMIN_FETCH_SH_CLIENT_FAILURE:
      return { ...state, client: initialState.client, client_loading: false };
    case ADMIN_FETCH_SH_STRIPE_CUSTOMERS_REQUEST:
      return {
        ...state,
        stripe_customers: initialState.stripe_customers,
        stripe_customers_loading: true,
      };
    case ADMIN_FETCH_SH_STRIPE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        stripe_customers: action.payload,
        stripe_customers_loading: false,
      };
    case ADMIN_FETCH_SH_STRIPE_CUSTOMERS_FAILURE:
      return {
        ...state,
        stripe_customers: initialState.stripe_customers,
        stripe_customers_loading: false,
      };
    case ADMIN_CREATE_STRIPE_CUSTOMERS_REQUEST:
    case ADMIN_CREATE_STRIPE_CUSTOMERS_SUCCESS:
    case ADMIN_CREATE_STRIPE_CUSTOMERS_FAILURE:
      return state;
    default:
      return state;
  }
};

export default ScheduleHelperReducer;
