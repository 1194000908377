import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LocaleSelect from './LocaleSelect';
import LogOut from './LogOut';
import { checkNewMessage } from '../../../actions/misc';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  close: {
    transform: 'rotate(180deg)',
  },
  badge: {
    width: '17px',
    height: '17px',
    minWidth: '0',
    borderRadius: '50%',
  },
}));

function UserInfo(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const { user, newMessage } = props;
  const classes = useStyles();

  useEffect(() => {
    props.checkNewMessage();
  }, []);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openModal = () => {
    setAnchorEl(null);
    setLogoutOpen(prevOpen => !prevOpen);
  };

  const handleLogoutClose = () => {
    setLogoutOpen(false);
  };

  if (!_.isEmpty(user)) {
    return (
      <div>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 30,
          }}
        >
          <Link to="/messages">
            <Badge
              overlap="rectangular"
              variant="dot"
              invisible={!newMessage}
              classes={{ badge: classes.badge }}
              color="secondary"
            >
              <MailOutlineRoundedIcon
                style={{ fill: 'grey', width: '32px', height: '32px' }}
              />
            </Badge>
          </Link>
          <div style={{ marginTop: 7 }}>
            <LocaleSelect />
          </div>
          <Button
            style={{
              borderColor: '#D0DBDB',
              borderRadius: 999,
              textTransform: 'none',
            }}
            variant="outlined"
            onClick={handleClick}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 10,
              }}
            >
              <Avatar
                style={{ backgroundColor: 'transparent' }}
                src={`${user.avatar?.url}`}
                className={classes.root}
              >
                <AccountCircleIcon />
              </Avatar>
              <span
                style={{
                  fontSize: 15,
                  fontWeight: '600',
                  color: '#545959',
                }}
              >
                {user.full_name}
              </span>
              <KeyboardArrowDownIcon
                className={anchorEl && classes.close}
                style={{ color: '#869191' }}
              />
            </Box>
          </Button>
          <Menu
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={openModal}>
              <Box
                sx={{
                  display: 'flex',
                  gap: 10,
                }}
              >
                <ExitToAppOutlinedIcon style={{ fill: '#808597' }} />
                <FormattedMessage defaultMessage="Logout" />
              </Box>
            </MenuItem>
          </Menu>
          <LogOut open={logoutOpen} handleClose={handleLogoutClose} />
        </Box>
      </div>
    );
  }
  return <div />;
}

UserInfo.propTypes = {
  user: PropTypes.object,
  newMessage: PropTypes.bool,
};

UserInfo.defaultProps = {};

function mapStateToProps(state) {
  const { newMessage } = state.misc;
  return {
    newMessage,
  };
}

export default connect(mapStateToProps, { checkNewMessage })(UserInfo);
