import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Logo from 'components/global/Logo';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import UserInfo from './UserInfo';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function TopBar({ user }) {
  const classes = useStyles();
  const { locale } = user;

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar
        style={{
          justifyContent: 'space-between',
          backgroundColor: '#FFFFFF',
          height: 80,
        }}
      >
        <div>
          <div>
            <Link to={user.is_admin ? '/admin' : '/dashboard'}>
              <Logo locale={locale} />
            </Link>
          </div>
        </div>
        <UserInfo user={user} />
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  user: PropTypes.object.isRequired,
};

export default TopBar;
