import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

/* eslint-disable react/jsx-props-no-spreading */

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        {}
        <Component {...props} />
      </Layout>
    )}
  />
);

AppRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  layout: PropTypes.func.isRequired,
};

export default AppRoute;
