import _ from 'lodash';

export const formatAddress = address => {
  if (!address) return '';

  const formatPart = (part, toUpper = false) => {
    if (typeof part !== 'string' || !part) return '';
    return toUpper ? part.toUpperCase() : _.startCase(part.toLowerCase());
  };

  const parts = [
    formatPart(address.street),
    formatPart(address.alternate_street),
    formatPart(address.city),
    formatPart(address.state?.code, true),
    formatPart(address.code, true),
    formatPart(address.country), // for Intl
  ];

  return parts
    .filter(Boolean)
    .join(' ')
    .trim();
};

export default formatAddress;
