let rootUrl;
let apiUrl;
let serverUrl;
let therapyUrl;
let stripeKey;
let ENV;
let country;
let oppositeCountryURL;
let amplitudeApiKey;
let ldClientId;
const hostname = window && window.location && window.location.hostname;

if (hostname === 'localhost') {
  ENV = 'DEVELOPMENT';
  country = 'CA';
  rootUrl = 'http://localhost:8080';
  apiUrl = 'http://localhost:3000/api';
  serverUrl = 'http://localhost:3000';
  therapyUrl = 'http://localhost:10101';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  oppositeCountryURL = 'https://portal.inkblotpractice.com';
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  ldClientId = '61aa7abf805c8710cfad8b2e';
} else if (
  hostname === 'practice.inkblottherapy.com' ||
  hostname === 'practitioner-stg.greenshieldplus.ca'
) {
  ENV = 'STAGING';
  country = 'CA';
  rootUrl = 'https://practitioner-stg.greenshieldplus.ca';
  apiUrl = 'https://api.inkblottherapy.com/api';
  serverUrl = 'https://api.inkblottherapy.com';
  therapyUrl = 'https://staging.inkblottherapy.com';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  oppositeCountryURL = 'https://us-staging.inkblotpractice.com';
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  ldClientId = '61ba3223f940930e6232e840';
} else if (hostname === 'practitioner.greenshieldplus.ca') {
  ENV = 'PRODUCTION';
  country = 'CA';
  rootUrl = 'https://practitioner.greenshieldplus.ca';
  apiUrl = 'https://api.inkblotpractice.com/api';
  serverUrl = 'https://api.inkblotpractice.com';
  therapyUrl = 'https://app.inkblottherapy.com';
  stripeKey = 'pk_live_iHFo2C08gcquDQatzHxxOdo9';
  oppositeCountryURL = 'https://portal.inkblotpractice.com';
  amplitudeApiKey = '19a913a504c377ea2dc305213318fbb6';
  ldClientId = '61a4f5fda8e6530f447dbcf9';
} else if (hostname === 'portal.inkblotpractice.com') {
  ENV = 'US PROD';
  country = 'US';
  rootUrl = 'https://portal.inkblotpractice.com';
  apiUrl = 'https://medapi.inkblottherapy.com/api';
  serverUrl = 'https://medapi.inkblottherapy.com';
  therapyUrl = 'https://portal.inkblottherapy.com';
  stripeKey = 'pk_live_iHFo2C08gcquDQatzHxxOdo9';
  oppositeCountryURL = 'https://practitioner.greenshieldplus.ca';
  amplitudeApiKey = '19a913a504c377ea2dc305213318fbb6';
  ldClientId = '667f146efad7f20fea512899';
} else if (hostname === 'us-staging.inkblotpractice.com') {
  ENV = 'US DEV';
  country = 'US';
  rootUrl = 'https://us-staging.inkblotpractice.com';
  apiUrl = 'https://usapi.inkblottherapy.com/api';
  serverUrl = 'https://usapi.inkblottherapy.com';
  therapyUrl = 'https://us-staging.inkblottherapy.com';
  oppositeCountryURL = 'https://practitioner-stg.greenshieldplus.ca';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  ldClientId = '61ba3223f940930e6232e840';
} else if (hostname === 'demo.inkblotpractice.com') {
  ENV = 'DEMO';
  country = 'CA';
  rootUrl = 'https://demo.inkblotpractice.com';
  apiUrl = 'https://demoapi.inkblottherapy.com/api';
  serverUrl = 'https://demoapi.inkblottherapy.com';
  therapyUrl = 'https://demo.inkblottherapy.com';
  stripeKey = 'pk_live_iHFo2C08gcquDQatzHxxOdo9';
  oppositeCountryURL = 'https://demo.inkblotpractice.com';
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  ldClientId = '622b6838855e3b14fa2358b2';
} else if (hostname === 'qa1-app.inkblotpractice.com') {
  ENV = 'QA1';
  country = 'CA';
  rootUrl = 'https://qa1-app.inkblotpractice.com';
  apiUrl = 'https://qa1-api.inkblottherapy.com/api';
  serverUrl = 'https://qa1-api.inkblottherapy.com';
  therapyUrl = 'https://qa1-app.inkblottherapy.com';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  oppositeCountryURL = 'https://us-qa1-app.inkblotpractice.com';
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  ldClientId = '63c83f0ac6d8f212b950f6bb';
} else if (hostname === 'us-qa1-app.inkblotpractice.com') {
  ENV = 'QA1 US';
  country = 'US';
  rootUrl = 'https://us-qa1-app.inkblotpractice.com';
  apiUrl = 'https://qa1-api.inkblottherapy.com/api';
  serverUrl = 'https://qa1-api.inkblottherapy.com';
  therapyUrl = 'https://us-qa1-app.inkblottherapy.com';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  oppositeCountryURL = 'https://qa1-app.inkblotpractice.com';
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  ldClientId = '63c83f0ac6d8f212b950f6bb';
} else if (hostname === 'qa2-app.inkblotpractice.com') {
  ENV = 'QA2';
  country = 'CA';
  rootUrl = 'https://qa2-app.inkblotpractice.com';
  apiUrl = 'https://qa2-api.inkblottherapy.com/api';
  serverUrl = 'https://qa2-api.inkblottherapy.com';
  therapyUrl = 'https://qa2-app.inkblottherapy.com';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  oppositeCountryURL = 'https://us-qa2-app.inkblotpractice.com';
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  ldClientId = '63c83f0fea045412753297dc';
} else if (hostname === 'us-qa2-app.inkblotpractice.com') {
  ENV = 'QA2 US';
  country = 'US';
  rootUrl = 'https://us-qa2-app.inkblotpractice.com';
  apiUrl = 'https://qa2-api.inkblottherapy.com/api';
  serverUrl = 'https://qa2-api.inkblottherapy.com';
  therapyUrl = 'https://us-qa2-app.inkblottherapy.com';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  oppositeCountryURL = 'https://qa2-app.inkblotpractice.com';
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  ldClientId = '63c83f0fea045412753297dc';
} else if (hostname === 'practitioner-cat.greenshieldplus.ca') {
  ENV = 'CAT';
  country = 'CA';
  rootUrl = 'https://practitioner-cat.greenshieldplus.ca';
  apiUrl = 'https://api-cat.inkblottherapy.com/api';
  serverUrl = 'https://api-cat.inkblottherapy.com';
  therapyUrl = 'https://app-cat.inkblottherapy.com';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  oppositeCountryURL = 'https://practitioner-cat.inkblotpractice.com';
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  ldClientId = '61ba3223f940930e6232e840';
}

export const ROOT_URL = `${rootUrl}`;
export const API_URL = `${apiUrl}`;
export const SERVER_URL = `${serverUrl}`;
export const THERAPY_URL = `${therapyUrl}`;
export const STRIPE_KEY = `${stripeKey}`;
export const ENVIRONMENT = `${ENV}`;
export const COUNTRY = `${country}`;
export const OTHER_COUNTRY_URL = `${oppositeCountryURL}`;
export const AMPLITUDE_API_KEY = amplitudeApiKey;
export const LD_CLIENT_ID = ldClientId;
