import React from 'react';
import PropTypes from 'prop-types';
import TopBar from './TopBar';
import SideBar from './SideBar';

function Navigation({ user }) {
  return (
    <>
      <TopBar user={user} />
      <SideBar user={user} />
    </>
  );
}

Navigation.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Navigation;
