// PageHelmet.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

const PageHelmet = ({ intl }) => (
  <Helmet>
    <title>
      {intl.formatMessage({
        defaultMessage: 'GreenShield Health',
      })}
    </title>
  </Helmet>
);

PageHelmet.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(PageHelmet);
