import _ from 'lodash';

import {
  ADMIN_FETCH_ADMIN_USERS_SUCCESS,
  ADMIN_FETCH_ADMIN_USERS_FAILURE,
  ADMIN_CREATE_ADMIN_USER_FAILURE,
  ADMIN_CREATE_ADMIN_USER_SUCCESS,
  ADMIN_DELETE_ADMIN_USER_FAILURE,
  ADMIN_DELETE_ADMIN_USER_SUCCESS,
} from '../../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case ADMIN_FETCH_ADMIN_USERS_SUCCESS:
      return action.payload.data;
    case ADMIN_CREATE_ADMIN_USER_SUCCESS:
      return action.payload.data;
    case ADMIN_CREATE_ADMIN_USER_FAILURE:
    case ADMIN_FETCH_ADMIN_USERS_FAILURE:
    case ADMIN_DELETE_ADMIN_USER_FAILURE:
      return action.payload;
    case ADMIN_DELETE_ADMIN_USER_SUCCESS:
      return _.omit(state, action.payload.data.id);
    default:
      return state;
  }
}
