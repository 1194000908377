import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { LanguageContext } from '../../../LanguageProvider';

const localeItems = [
  {
    value: 'en',
    label: 'English (EN)',
  },
  {
    value: 'fr',
    label: 'Français (FR)',
  },
];

const useStyles = makeStyles({
  icon: {
    fill: 'grey',
  },
  root: {
    color: '#545959',
    fontSize: '15px',
    fontWeight: '400',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
});

function LocaleSelect({ appLocale }) {
  const classes = useStyles();

  return (
    <LanguageContext.Consumer>
      {({ setLocale }) => (
        <Select
          disableUnderline
          value={appLocale}
          inputProps={{
            classes: {
              icon: classes.icon,
              root: classes.root,
            },
          }}
          renderValue={value => value.toUpperCase()}
          IconComponent={KeyboardArrowDownIcon}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          {localeItems.map(item => (
            <MenuItem
              key={item.value}
              value={item.value}
              onClick={() => setLocale(item.value)}
              disabled={item.value === appLocale}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </LanguageContext.Consumer>
  );
}

LocaleSelect.propTypes = {
  appLocale: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const {
    misc: { appLocale },
  } = state;

  return { appLocale };
}

export default connect(mapStateToProps)(LocaleSelect);
