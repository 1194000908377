import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { loadingBarReducer } from 'react-redux-loading-bar';
import Clients from './client';
import ClientMatchReducer from './reducer_client_match';
import AuthReducer from './reducer_auth';
import AvailabilityReducer from './reducer_availability';
import UserReducer from './reducer_user';
import UserSettingReducer from './reducer_user_setting';
import AppointmentsReducer from './reducer_appointments';
import AppointmentTypesReducer from './reducer_appointment_types';
import SessionReducer from './reducer_session';
import MiscReducer from './reducer_misc';
import ResourceReducer from './reducer_resources';
import AdminUserReducer from './admin/reducerUser';
import AdminSuperUsersReducer from './admin/reducer_admin_users';
import DocumentsReducer from './reducer_documents';
import AdminAppointmentsReducer from './admin/reducer_appointments';
import AdminPreferenceReducer from './admin/reducer_preference';
import AdminDiscountCodeReducer from './admin/reducer_discount_codes';
import AdminCompaniesReducer from './admin/reducer_companies';
import PreferencesReducer from './reducer_preferences';
import AnalyticsReducer from './reducer_analytics';
import adminPaginationReducer from './admin/reducer_pagination';
import PaginationReducer from './reducer_pagination';
import StripeCustomersReducer from './reducer_stripe_customers';
import ScheduleHelperReducer from './reducer_schedule_helper';
import LifeCoachingReducer from './reducer_user_life_coaching';
import ChatsReducer from './reducer_messages';
import GoverningBodiesReducer from './reducer_governing_bodies';
import SessionStatusReducer from './reducer_session_status';
import AdminMiscReducer from './admin/reducer_misc';
import AdminAvailabilityReducer from './admin/reducer_availability';
import AdminScheduleHelperReducer from './admin/reducer_schedule_helper';
import SnackbarReducer from './reducer_snackbar';
import ducksReducer from '../ducks';

const appReducer = combineReducers({
  form: formReducer,
  analytics: AnalyticsReducer,
  auth: AuthReducer,
  availabilities: AvailabilityReducer,
  user: UserReducer,
  user_setting: UserSettingReducer,
  chats: ChatsReducer,
  clientMatch: ClientMatchReducer,
  appointments: AppointmentsReducer,
  appointment_types: AppointmentTypesReducer,
  session: SessionReducer,
  misc: MiscReducer,
  resources: ResourceReducer,
  admin_users: AdminUserReducer,
  admin_super_users: AdminSuperUsersReducer,
  documents: DocumentsReducer,
  admin_appointments: AdminAppointmentsReducer,
  admin_preference: AdminPreferenceReducer,
  admin_discount_codes: AdminDiscountCodeReducer,
  adminCompanies: AdminCompaniesReducer,
  preferences: PreferencesReducer,
  loadingBar: loadingBarReducer,
  adminPagination: adminPaginationReducer,
  pagination: PaginationReducer,
  life_coaching: LifeCoachingReducer,
  admin_misc: AdminMiscReducer,
  ...Clients,
  stripe_customers: StripeCustomersReducer,
  governing_bodies: GoverningBodiesReducer,
  sessionStatus: SessionStatusReducer,
  schedule_helper: ScheduleHelperReducer,
  adminAvailability: AdminAvailabilityReducer,
  adminScheduleHelper: AdminScheduleHelperReducer,
  global_snackbar: SnackbarReducer,
  ducks: ducksReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'UNAUTH_USER') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
